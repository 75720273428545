.notCompletedPanelContainer {
  border-radius: 10px;
  width: 100%;
  padding: 4px;
  color: #323130;
}

.heading {
  font-size: 18px;
  font-weight: 600;
}

.attention {
  color: #800000;
}
