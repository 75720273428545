.itemContainer {
  width: 326px;
  background-color: #fff;
  border-radius: 11px;
  padding: 16px 20px;
  margin: 15px 20px;
}

.emailSubject {
  font-size: 12px;
  font-weight: 600;
}

.dataItemHeading {
  font-weight: 600;
}

.dataLine {
  width: 100%;
}

.dataLine > p {
  width: 100%;
  margin: 0;
  padding: 0;
}

.attentionText {
  color: #800000;
}

.normalText {
  color: #323130;
}
