.itemContainer {
  width: 326px;
  background-color: #fff;
  border-radius: 11px;
  padding: 16px 20px;
  margin: 15px 20px;
}

.emailSubject {
  font-size: 12px;
  font-weight: 600;
}

.dataItemHeading {
  font-weight: 600;
}

.dataLine {
  width: 100%;
}

.dataLine > p {
  width: 100%;
  margin: 0;
  padding: 0;
}

.actionLinkContainer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.actionLink {
  border: none;
  display: block;
  background-color: #e1242a;
  color: #fff;
  height: 32px;
  width: 256px;
  text-decoration: none;
  border-radius: 11px;
  box-sizing: border-box;
  padding: 6px 12px;
}

.actionLink > span {
  height: 16px;
}

.attentionText {
  color: #800000;
}

.normalText {
  color: #323130;
}
