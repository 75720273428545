.tabsContainer {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.tabsLeftContainer {
  display: flex;
  flex-wrap: nowrap;
}

.tabsRightContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.tab {
  width: 140px;
  height: 32px;
  cursor: pointer;
  font-size: 14px;
  margin: 4px 15px;
}

.selectedTab {
  color: #fff;
  background-color: #e1242a;
  border: none;
}

.notSelectedTab {
  color: #e1242a;
  background-color: #fff;
  border: 1px solid #000;
}
